<template>
	<div>
		<!-- 码商报表 -->
		<div class="queryBox mb20">
			
				<el-cascader v-model="carder_id" class="mr20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options"
				 :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang"></el-cascader>
				 
			<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
			<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期"
			 end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
			<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
			<!-- <span class="inlineBlock mr20">
				<span>充值类型：</span>
				<el-select v-model="pay_type" placeholder="请选择" size="mini" clearable>
					<el-option v-for="item in paywayArr" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</span> -->
			<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">搜索</el-button>
			<el-button size="mini" type="primary" icon="el-icon-document"  @click="getExcel"
				:disabled="!tableData.length">导出报表</el-button>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" stripe style="width: 100%" v-loading="loading" size="mini">
				<el-table-column label="序号" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.carder_name && scope.row.carder_name.includes('总计')">{{scope.row.carder_name}}</span>
						<span v-else="">{{scope.$index + 1}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="shanghushu" label="搬砖工号" width="200" align="center">
					<template slot-scope="scope">
						<div class="textCenter"  v-if="!(scope.row.carder_name && scope.row.carder_name.includes('总计'))">{{scope.row.carder_name}}</div>
						<!-- <div v-if="scope.row.agent_username" class="textCenter">{{scope.row.agent_username}}</div> -->
					</template>
				</el-table-column>
				<el-table-column label="提交" align="center">
					<el-table-column prop="add_count" label="单数" align="center"></el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span  v-if="scope.$index >= tableData.length-2">{{scope.row.add_money | tofixed}}</span>
							<el-link v-else="" type="primary" @click="gotopage('/passagewayReport',scope.row)">{{scope.row.add_money | tofixed}}</el-link>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="扫码" align="center">
					<el-table-column prop="scan_count" label="单数" align="center"></el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.scan_money | tofixed}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="成功" align="center">
					<el-table-column prop="success_count" label="单数" align="center"></el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.success_money | tofixed}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="支付率" align="center">
					<el-table-column label="单数" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.pay_count_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.pay_money_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="成功率" align="center">
					<el-table-column label="单数" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.success_count_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
					<el-table-column label="金额" align="center">
						<template slot-scope="scope">
							<span>{{scope.row.success_money_ratio | tofixed}}%</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column label="支付" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.fee_gold_carder | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="代理" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.fee_gold_ag | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="平台" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.platform_gold | tofixed}}</span>
					</template>
				</el-table-column>
				<el-table-column label="商家" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.trader_gold | tofixed}}</span>
					</template>
				</el-table-column>
			</el-table>
		</template>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>

	</div>
</template>

<script>
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				date: '',
				input: '',
				tableData: [],
				pageSizesArr: [100, 200,500,1000],
				pageSize: 100,
				total: 0,
				page: 1,
				paywayArr: [],
				// pay_type: '',
				platformArr: [{
					value: '',
					label: '全部'
				}],
				platform: '',
				agent: '' ,//按代理查
				height:0,
				options: [],
				carder_id: '',
			}
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			// this.agent = this.$route.query.agent // 获取通道报表传过来的充值类型
			this.carderReport()
			this.treeList()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
			})
		},
		mounted(){
			let self = this
			document.onkeydown = function(e) {
			  let ev = document.all ? window.event : e
			  if (ev.keyCode === 13) {
				  if(!self.loading){
					  self.getData()
				  }
				
			  }
			}
		},
		destroyed() {
			document.onkeydown = null
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			//导出Excel
			getExcel() {
				//manager/order/exportList
				let username = local.get('username');
				let tid = local.get('tid')
				let start_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0])), false) : '';
				let end_time = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1])), false) : '';
				let href =
					`${this.$baseURL}manager/report/exportCarder?username=${username}&tid=${tid}&startdate=${start_time}&enddate=${end_time}`
				// window.location.href = href
				window.open(href)
			},
			agentChang(value) {
				this.carder_id = value
			},
			treeList() {
				this.$api.treeList({}).then(res => {
					if (res.status === 1) {
						this.options = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			setDate(day) {
				let data = this.date?this.date[0]:this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			
			//查看列表
			carderReport() {
				let data = {
					// startdate: this.date,
					startdate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					size: this.pageSize,
					page: this.page,
					// pay_type: this.pay_type,
					agent_username:this.agent,
					carder_children:this.carder_id,
				}
				// this.loading = true
				this.disLoading = true
				this.$api.carderReport(data).then(res => {
					this.loading = false
					this.disLoading = false
					if (res.status == 1) {
						this.tableData = res.data.data
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}

				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			getData() {
				this.page = 1;
				this.carderReport()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.carderReport()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.page = val
				this.carderReport()
			},
			gotopage(path, item) {
				this.$router.push({
					path: path,
					query: {
						carder_username: item.carder_username,
						date:[this.$util.timestampToTime(Date.parse(new Date(this.date[0]))),this.$util.timestampToTime(Date.parse(new Date(this.date[1])))]
					}
				})
			},
			
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					// let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
				})
			}
		},
	}
</script>

<style>
</style>
